.Scoreboard {
	width: 80rem;
	height: 40rem;
	display: flex;
	margin-top: 3rem;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
}
