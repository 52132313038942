.banners {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;

	img {
		width: 24rem;
		height: 13.5rem;
		padding: 0.5rem;
		border: 1px solid #444444;
	}
}
