.App {
	background-color: #111111;
	min-height: 100vh;
	color: white;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;

	main {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
