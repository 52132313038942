@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.Logo {
	width: 80rem;
	text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.Logo img {
	width: 50%;
}

.Logo p {
	width: 50%;
	margin: 2rem;
	font-size: 3.5rem;
	font-family: 'Edo SZ', sans-serif;
	text-transform: uppercase;
	color: #00ff00;
}
