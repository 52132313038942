.Team {
	width: 40rem;
	height: 8rem;
	display: flex;
	flex-direction: column;

	.flames {
		width: 38.4rem;
		height: 8rem;
		margin: 0 0.8rem -1rem;
		display: none;
	}

	> div {
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid #444444;
		margin: 10px;
	}

	h1,
	p {
		margin: 0;
	}

	p {
		font-size: 1.2rem;
	}

	.text {
		height: 6rem;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		p {
			color: #888888;
		}
	}

	.rank,
	.score {
		font-family: 'Edo SZ';
		font-size: 4rem;
		margin: 0 1rem;
	}

	.rank {
		width: 5rem;
		margin-right: 1rem;
		color: #00ff00;
	}

	.score {
		text-align: right;
	}
}

.on-fire {
	color: #ffc334;

	.rank {
		color: inherit;
	}

	.flames {
		display: block;
	}

	height: 15rem;
	margin-top: -7rem;

	> div {
		border-top: none;
	}
}
