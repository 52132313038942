@font-face {
	font-family: 'Edo SZ';
	src: url('../public/fonts/edosz.ttf');
}

html {
	font-size: 10px;

	@media screen and (min-width: 1920px) and (min-height: 1080px) {
		font-size: 13px;
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
}
